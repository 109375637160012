import React, { useEffect, useRef, useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import coma from "../../images/fintech/coma.svg"
import Row from "react-bootstrap/Row"
import Slider from "react-slick"
import arrowleft from "../../images/cyberSecurity/left.png"
import arrowright from "../../images/cyberSecurity/right.png"
import * as styles from "./Kind2.module.scss"
import nymcard from "../../images/fintech/nymcard.svg"
import acorons from "../../images/fintech/acorons.svg"
import akru from "../../images/fintech/akru.svg"
import spider from "../../images/fintech/spider-af.svg"
import forti from "../../images/fintech/forti.svg"

const card = [
  {
    name: `Omar Once`,
    founder: `CEO & Founder, NymCard`,
    dec: `"  InvoZone's expertise exceeded expectations. Their tailored
    solutions transformed our services and propelled us to the
    forefront of innovation in the fintech landscape. Their
    dedication and attention to detail set them apart."`,
    img: nymcard,
  },
  {
    name: `Noah Kerner`,
    founder: `CEO, Acorns.`,
    dec: `Efficiency soared with InvoZone's app development. Their
    focus on seamless transactions and top-notch service has
    made a remarkable impact on the way we do business. Their
    ability to understand our unique needs is truly
    commendable.`,
    img: acorons,
  },
  {
    name: `Viji Rajasundram`,
    founder: ` Technology Advisor, AkruNow`,
    dec: `InvoZone's expertise was pivotal in our project's success.
    Their team's dedication and innovative solutions were
    evident in every aspect of our fintech app. Working with
    them was a truly rewarding experience.t`,
    img: akru,
  },
  {
    name: `Satoko Ohtsuki`,
    founder: ` CEO, SpiderAf`,
    dec: `   InvoZone's app development transformed our business
    landscape. Their seamless integration and user-focused
    design turned our vision into reality. Their
    professionalism and commitment were truly commendable.`,
    img: spider,
  },
  {
    name: `Michael Smith`,
    founder: ` Founder`,
    dec: ` Choosing InvoZone for our fintech app was a decision that
    paid off immensely. Their expertise in the field and
    commitment to delivering exceptional solutions aligned
    perfectly with our needs. We couldn't be happier with the
    outcome.`,
    img: forti,
  },
]

function Arrow(props) {
  const { onClick } = props
  return (
    <div className={styles.iconContainer}>
      <div className={styles.portfolioArrowRightIconCover} onClick={onClick}>
        <img
          src={arrowright}
          onClick={onClick}
          className={styles.portfolioArrowIcon}
          decoding="async"
          loading="lazy"
          alt="arrow"
        />
      </div>
    </div>
  )
}
function PrevArrow(props) {
  const { onClick } = props
  return (
    <div className={styles.iconContainerLeft}>
      <div className={styles.portfolioArrowIconCover} onClick={onClick}>
        <img
          src={arrowleft}
          className={styles.portfolioArrowIcon}
          decoding="async"
          loading="lazy"
          alt="arrow"
        />
      </div>
    </div>
  )
}

const InvoZoneHelps = ({ strapiData }) => {
  const slide1 = useRef(null)
  const [current, setCurrent] = useState(0)
  const [current_1, setCurrent_1] = useState(0)
  const [current_2, setCurrent_2] = useState(0)

  useEffect(() => {
    ;(current === strapiData?.cards.length - 3 ||
      current_1 === strapiData?.cards.length - 1 ||
      current_2 === strapiData?.cards.length - 1) &&
      setTimeout(() => {
        slide1?.current?.slickGoTo(0)
      }, 5000)
  }, [current, current_1, current_2])

  const settings = {
    autoplay: false,
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    speed: 200,
    prevArrow: <PrevArrow />,
    nextArrow: <Arrow />,
    beforeChange: (current, next) => setCurrent(next),
    lazyLoad: "ondemand",
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 1,
          beforeChange: (current, next) => setCurrent_1(next),
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          beforeChange: (current, next) => setCurrent_2(next),
        },
      },
    ],
  }

  return (
    <React.Fragment>
      <div className={styles.premiumBg}>
        <div className={styles.teamWork}>
          <Container>
            <h2 className={styles.heading}>
              What does the customer say about us
            </h2>
            <Row className={`gap-30 ${styles.cardWrapper}`}>
              <Slider {...settings} className={styles.SliderWidth} ref={slide1}>
                {card?.map((e, i) => (
                  <Col xs={12} key={i}>
                    <div className={styles.card}>
                      <img
                        src={coma}
                        decoding="async"
                        loading="lazy"
                        alt="icon"
                      />
                      <p className={styles.decs}>{e?.dec}</p>
                      <hr />
                      <div className={styles.reviwer}>
                        <img src={e?.img} alt="icon" />
                        <div>
                          <h3>{e?.name}</h3>
                          <p className={styles.found}>{e?.founder}</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Slider>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default InvoZoneHelps
