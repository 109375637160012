import React, { useEffect, useRef, useState } from "react"
import { Link } from "gatsby"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Row from "react-bootstrap/Row"
import Tab from "react-bootstrap/Tab"
import arrow from "../../images/fintech/arrow.svg"
import Slider from "react-slick"
import "./fintech.scss"
import * as styles from "./Featured2.module.scss"
import "../fintech-landing-ar/Featured.scss"

const Featured = ({ strapiData }) => {
  const [current, setCurrent] = useState(0)
  const slide1 = useRef(null)
  const slide2 = useRef(null)

  useEffect(() => {
    slide1?.current?.slickGoTo(current)
    slide2?.current?.slickGoTo(current)
    current === 4 &&
      setTimeout(() => {
        slide1?.current?.slickGoTo(0)
        slide2?.current?.slickGoTo(0)
      }, 5000)
  }, [current])

  const settings = {
    dots: true,
    autoplay: false,
    arrows: false,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    infinite: false,
    dotsClass: "new-dot-app",
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 0,
    lazyLoad: "ondemand",
    beforeChange: (current, next) => setCurrent(next),
    swipeToSlide: true,
    variableWidth: true,
  }

  return (
    <div className="p-85">
      <Container>
        <div className="featured">
          <Tab.Container id="left-tabs-example" defaultActiveKey="NymCard">
            <Row className="gap-30">
              <Col lg={6} xs={12}>
                <p className={styles.vision}>Portfolio</p>
                <h2 className={styles.heading}>{strapiData?.title}</h2>
                <Slider {...settings} ref={slide1}>
                  {strapiData?.cards?.map((e, i) => (
                    <Nav variant="pills" className={`featured2`}>
                      <Nav.Item key={i}>
                        <Nav.Link eventKey={`${e?.title}`}>{e?.title}</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  ))}
                </Slider>
              </Col>
              <Col lg={6} xs={12} className="pl-lg-0">
                <Tab.Content>
                  {strapiData?.cards?.map((e, i) => (
                    <Tab.Pane eventKey={`${e?.title}`} key={i}>
                      <div className={styles.boxImg}>
                        <img
                          decoding="async"
                          loading="lazy"
                          src={e?.img}
                          className={styles.portImg}
                          alt="icon"
                        />
                        <div className={styles.boxContent}>
                          <h3>{e?.title}</h3>
                          <p>{e?.subTitle}</p>
                          <div className={styles.link}>
                            <div className={`${styles.bannerBtn}`}>
                              <Link className={styles.btn_white2_border}>
                                Banking App
                              </Link>
                            </div>
                            <div>
                              <a href={e?.link}>
                                <img src={arrow} alt="icon" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </Container>
    </div>
  )
}
export default Featured
