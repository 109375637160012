import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import image1 from "../../images/fintech/Group.svg"
import "./fintech.scss"
import * as styles from "./Accelerate.module.scss"

const Faqs = ({ strapiData }) => {
  const addOrRemoveClass = event => {
    const clickedElement = event.currentTarget

    var allBoxes = document.getElementsByClassName("cardAcc")
    // console.log("Runnnn", clickedElement)
    for (var i = 0; i < allBoxes.length; i++) {
      allBoxes[i].classList.remove("cardClick")
    }
    clickedElement.classList.toggle("cardClick")
  }
  return (
    <div className={styles.Behold}>
      <Container>
        <Row>
          <Col>
            <Row>
              <Col lg={6}>
                <p className={styles.vision}>{strapiData?.subTitle2}</p>
                <h2 className={styles.heading}>{strapiData?.title}</h2>
                {strapiData.cards?.map((e, i) => (
                  <div
                    className={`${styles.card} cardAcc ${
                      i === 0 && "cardClick"
                    }`}
                    onClick={addOrRemoveClass}
                  >
                    <h3>{e?.title}</h3>
                    <p>{e?.subTitle}</p>
                  </div>
                ))}
              </Col>
              <Col lg={6}>
                <div>
                  <div className={styles.cardBg}> </div>
                  <Col xs={12} className={styles.sliderImg}>
                    <img
                      src={image1}
                      decoding="async"
                      loading="lazy"
                      alt="icon"
                    />
                  </Col>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Faqs
