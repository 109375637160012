// extracted by mini-css-extract-plugin
export var SliderWidth = "Kind2-module--SliderWidth--dcd77";
export var arrowImg = "Kind2-module--arrowImg--8d0be";
export var card = "Kind2-module--card--fa465";
export var cardWrapper = "Kind2-module--cardWrapper--71e0b";
export var cards = "Kind2-module--cards--64a45";
export var decs = "Kind2-module--decs--5e0d8";
export var description = "Kind2-module--description--10e47";
export var fineBg = "Kind2-module--fineBg--d4fe1";
export var found = "Kind2-module--found--f5586";
export var heading = "Kind2-module--heading--9eea0";
export var headingPremium = "Kind2-module--headingPremium--edc53";
export var iconContainer = "Kind2-module--iconContainer--376b7";
export var iconContainerLeft = "Kind2-module--iconContainerLeft--2ad0d";
export var portfolioArrowIcon = "Kind2-module--portfolioArrowIcon--28c74";
export var portfolioArrowIconCover = "Kind2-module--portfolioArrowIconCover--b6b45";
export var portfolioArrowRightIconCover = "Kind2-module--portfolioArrowRightIconCover--9e10b";
export var premiumImg = "Kind2-module--premiumImg--95c36";
export var providingImg = "Kind2-module--providingImg--4e916";
export var reviwer = "Kind2-module--reviwer--371ac";
export var teamButton = "Kind2-module--teamButton--eaa48";
export var teamWork = "Kind2-module--teamWork--d5d5b";
export var trailBg = "Kind2-module--trailBg--eb999";