import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import fintech from "../../images/fintech/desktop-hero-bg2.png"
import circle from "../../images/fintech/circleeng.svg"
import * as styles from "./Banner.module.scss"
import { openCalendlyPopup } from "../../utils"

const Banner = ({ strapiData }) => {
  return (
    <div className={styles.banner}>
      <Container>
        <Row>
          <Col xl={6} lg={12}>
            <div>
              <div className={styles.heading}>
                <img
                  src={circle}
                  className={styles.circle}
                  decoding="async"
                  loading="lazy"
                  alt="fintech"
                />
                <h1 className={styles.bannerHeading}>
                  Searching for Cutting-Edge{" "}
                  <span className={styles.fin}>Fintech</span> Software
                  <span> Solutions</span> in <span>Saudi Arabia</span>?
                </h1>
                <p>{strapiData?.subTitle}</p>
                <div className={`${styles.bannerBtn}`}>
                  {strapiData?.buttons[0] && (
                    <button
                      className={styles.btn_white2_border}
                      onClick={() => {
                        openCalendlyPopup()
                        return false
                      }}
                    >
                      Schedule Your Slot Now
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Col>
          <Col xl={6} lg={12}>
            <div
              className={styles.bannerImg}
              decoding="async"
              loading="lazy"
              alt="fintech"
            >
              <img src={fintech} alt="icon" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Banner
