import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Testimonials.module.scss"
import { openCalendlyPopup } from "../../utils"

const Testimonials = ({ strapiData }) => {
  return (
    <div className={`${styles.Testimonials}`}>
      <Container>
        <p className={styles.vision}>{strapiData?.subTitle2}</p>
        <h2 className={styles.heading}>{strapiData?.title}</h2>
        <Row className={`gap-30 `}>
          {strapiData?.cards?.map((e, i) => (
            <Col
              key={i}
              xl={4}
              md={6}
              xs={12}
              className={`gap-30 d-flex ${styles.cardUp}`}
            >
              <div className={styles.card}>
                <img
                  src={e?.icon}
                  decoding="async"
                  className={styles.icon1}
                  loading="lazy"
                  alt="icon"
                />
                <img
                  alt="icon"
                  src={e?.icon2}
                  decoding="async"
                  loading="lazy"
                  className={styles.icon2}
                />
                <h3>{e?.title}</h3>
                <p>{e?.subTitle}</p>
              </div>
            </Col>
          ))}
        </Row>
        <div className={`${styles.bannerBtn}`}>
          {strapiData?.buttons[0] && (
            <button
              className={styles.btn_white2_border}
              onClick={() => {
                openCalendlyPopup()
                return false
              }}
            >
              Bring Your Vision Into Reality
            </button>
          )}
        </div>
      </Container>
    </div>
  )
}

export default Testimonials
