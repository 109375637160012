import React from "react"
import Container from "react-bootstrap/Container"
import acorons from "../../images/fintech/acorons.svg"
import akru from "../../images/fintech/akru.svg"
import coma from "../../images/fintech/coma.svg"
import forti from "../../images/fintech/forti.svg"
import nymcard from "../../images/fintech/nymcard.svg"
import spider from "../../images/fintech/spider-af.svg"
import * as styles from "./Kinds.module.scss"
import "./fintech.scss"

const InvoZoneHelps = () => {
  function setActive() {
    const educationCards = document.querySelectorAll(".education-card")
    educationCards.forEach(card => {
      card.classList.remove("active")
    })

    this.classList.add("active")
  }

  React.useEffect(() => {
    const educationCards = document.querySelectorAll(".education-card")
    educationCards.forEach(card => {
      card.addEventListener("click", setActive)
    })
  }, [])

  return (
    <React.Fragment>
      <div className={styles.premiumBg}>
        <Container>
          <p className={styles.vision}>Testimonials</p>
          <h2 className={styles.heading}>
            What Do Our Customers Say About Us?
          </h2>
          <div class="education" id="education">
            <div class="cards ms-5">
              <div id="one" class="education-card active">
                <div class="info1">
                  <img src={coma} className="coma" alt="icon" />
                  <p className="dec">
                    InvoZone's expertise exceeded expectations. Their tailored
                    solutions transformed our services and propelled us to the
                    forefront of innovation in the fintech landscape. Their
                    dedication and attention to detail set them apart.
                  </p>
                  <hr />
                  <div className={styles.reviwer}>
                    <img src={nymcard} className="person" alt="icon" />
                    <div className="name">
                      <h3>Omar Once</h3>
                      <p className={styles.found}>CEO & Founder, NymCard</p>
                    </div>
                  </div>
                </div>
              </div>
              <div id="one" class="education-card">
                <div class="info1">
                  <img src={coma} className="coma" alt="icon" />
                  <p className="dec">
                    Efficiency soared with InvoZone's app development. Their
                    focus on seamless transactions and top-notch service has
                    made a remarkable impact on the way we do business. Their
                    ability to understand our unique needs is truly commendable.
                  </p>
                  <hr />
                  <div className={styles.reviwer}>
                    <img src={acorons} className="person" alt="icon" />
                    <div className="name">
                      <h3>Noah Kerner</h3>
                      <p className={styles.found}>CEO, Acorns.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div id="one" class="education-card">
                <div class="info1">
                  <img src={coma} className="coma" alt="icon" />
                  <p className="dec">
                    InvoZone's expertise was pivotal in our project's success.
                    Their team's dedication and innovative solutions were
                    evident in every aspect of our fintech app. Working with
                    them was a truly rewarding experience.
                  </p>
                  <hr />
                  <div className={styles.reviwer}>
                    <img src={akru} className="person" alt="icon" />
                    <div className="name">
                      <h3>Viji Rajasundram</h3>
                      <p className={styles.found}>
                        Technology Advisor, AkruNow
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div id="one" class="education-card">
                <div class="info1">
                  <img src={coma} className="coma" alt="icon" />
                  <p className="dec">
                    InvoZone's app development transformed our business
                    landscape. Their seamless integration and user-focused
                    design turned our vision into reality. Their professionalism
                    and commitment were truly commendable.
                  </p>
                  <hr />
                  <div className={styles.reviwer}>
                    <img src={spider} className="person" alt="icon" />
                    <div className="name">
                      <h3>Satoko Ohtsuki</h3>
                      <p className={styles.found}>CEO, SpiderAf</p>
                    </div>
                  </div>
                </div>
              </div>
              <div id="one" class="education-card">
                <div class="info1">
                  <img src={coma} className="coma" alt="icon" />
                  <p className="dec">
                    Choosing InvoZone for our fintech app was a decision that
                    paid off immensely. Their expertise in the field and
                    commitment to delivering exceptional solutions aligned
                    perfectly with our needs. We couldn't be happier with the
                    outcome.
                  </p>
                  <hr />
                  <div className={styles.reviwer}>
                    <img src={forti} className="person" alt="icon" />
                    <div className="name">
                      <h3>Michael Smith</h3>
                      <p className={styles.found}>Founder, Forti.io.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default InvoZoneHelps
