// extracted by mini-css-extract-plugin
export var backDec = "Build-module--backDec--03853";
export var bannerBtn = "Build-module--bannerBtn--e4248";
export var btn_white2_border = "Build-module--btn_white2_border--43f24";
export var cardWrapper = "Build-module--cardWrapper--bac3a";
export var flipbox = "Build-module--flipbox--a823b";
export var flipboxback = "Build-module--flipboxback--b11da";
export var flipboxfront = "Build-module--flipboxfront--237f6";
export var flipboxinner = "Build-module--flipboxinner--17d95";
export var heading = "Build-module--heading--8b230";
export var servicesbox = "Build-module--servicesbox--9380b";
export var subservice = "Build-module--subservice--fbf81";
export var teamBtn = "Build-module--teamBtn--788c0";
export var vision = "Build-module--vision--e24c5";