// extracted by mini-css-extract-plugin
export var faqButton = "Faqs2-module--faqButton--6668e";
export var faqsNum = "Faqs2-module--faqsNum--2463e";
export var faqsSub = "Faqs2-module--faqsSub--5aa4a";
export var head = "Faqs2-module--head--5b3c5";
export var heading = "Faqs2-module--heading--0d533";
export var quesColor = "Faqs2-module--quesColor--e36e3";
export var quesColorSelected = "Faqs2-module--quesColorSelected--f6b18";
export var section = "Faqs2-module--section--6425e";
export var serial = "Faqs2-module--serial--b4e53";
export var serial2 = "Faqs2-module--serial2--330a9";
export var textColor = "Faqs2-module--textColor--cbded";