import React from "react"
import Accelerate from "../../components/fintech-landing/Accelerate"
import Banner from "../../components/fintech-landing/Banner"
import Build from "../../components/fintech-landing/Build"
import Discover from "../../components/fintech-landing/Discover"
import Faqs2 from "../../components/fintech-landing/Faqs2"
import Featured from "../../components/fintech-landing/Featured"
import Featured2 from "../../components/fintech-landing/Featured2"
import Kind2 from "../../components/fintech-landing/Kind2"
import Kinds from "../../components/fintech-landing/Kinds"
import Toggle from "../../components/fintech-landing/toggle"
import VideoSection from "../../components/fintech-landing/VideoSection"
import Testimonials from "../../components/fintech-landing/Testimonials"
import Lending from "../../images/fintech/Lending-LendTech.svg"
import Payments from "../../images/fintech/Payments-PayTech.svg"
import Regulatory from "../../images/fintech/Regulatory-RegTech.svg"
import blockchain from "../../images/fintech/blockchain-and-cryptocurrency.svg"
import insurance from "../../images/fintech/insurance-InsurTech.svg"
import mobile from "../../images/fintech/mobile-payments.svg"
import step01 from "../../images/fintech/process-step-1-dark.svg"
import step1 from "../../images/fintech/process-step-1-light.svg"
import step02 from "../../images/fintech/process-step-2-dark.svg"
import step2 from "../../images/fintech/process-step-2-light.svg"
import step03 from "../../images/fintech/process-step-3-dark.svg"
import step3 from "../../images/fintech/process-step-3-light.svg"
import robo from "../../images/fintech/robo-advising-and-stock-trading-apps.svg"
import seamless2 from "../../images/fintech/seamless-integration-comprehensive-solutions-dark.svg"
import seamless from "../../images/fintech/seamless-integration-comprehensive-solutions.svg"
import strategic2 from "../../images/fintech/strategic-collaboration-dark.svg"
import strategic from "../../images/fintech/strategic-collaboration-light.svg"
import tailored2 from "../../images/fintech/tailored-fintech-app-development-dark.svg"
import tailored from "../../images/fintech/tailored-fintech-app-development-light.svg"
import trading from "../../images/fintech/trading-tradetech.svg"
import Acorns from "../../images/fintech/Acorns.jpeg"
import Akru from "../../images/fintech/Akru.png"
import Forti from "../../images/fintech/Forti.png"
import Nymcard from "../../images/fintech/Nymcard.png"
import SpiderAF from "../../images/fintech/SpiderAF.png"
import MainLayout from "../../layouts/MainLayout"
export const Head = () => (
  <>
    <title>Fintech Software Development Company in Saudi Arabia</title>
    <meta
      name="description"
      content="Explore innovative Fintech software development company in KSA designed to transform your financial experience. Our experts develop top-notch fintech apps, ensuring seamless transactions."
    />
    <meta name="robots" content="noindex, nofollow"></meta>
    <meta name="googlebot" content="noindex, nofollow"></meta>
  </>
)
const FintechKSA = ({ location }) => {
  //   console.log(data)
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1281) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1281)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const en_ver = [
    {
      title:
        "Searching for Cutting-Edge Fintech Software Solutions in Saudi Arabia?",
      subTitle:
        "Searching for fintech app development services for your startup, bank, or payment company in KSA? Your search ends here with InvoZone.",
      buttons: [
        {
          title: "Schedule Your Slot Now",
          url: "contact-us",
        },
      ],
    },
    {
      title: "Discover Our Expertise in Fintech Software Solutions",
      subTitle:
        "With 15+ years of expertise, we're Fintech pioneers. Our mastery lies in tailoring solutions to fintech startups, banks, and payment firms in Saudi Arabia.",
      subTitle2: "Your Vision, Our Expertise",
      cards: [
        {
          title: "Tailored Fintech App Development",
          subTitle:
            "Fuel the growth of Saudi banks and fintech startups with our bespoke app development solutions designed for modern challenges, driving your revenue and success.",
          icon: tailored,
          icon2: tailored2,
        },
        {
          title: "Seamless Integration, Comprehensive Solutions",
          subTitle:
            "Seamlessly merge cutting-edge back-end and user-friendly front-end interfaces. Our services enhance innovation, drive revenue growth, and supercharge your startup journey.",
          icon: seamless,
          icon2: seamless2,
        },
        {
          title: "Strategic Collaboration",
          subTitle:
            "Unlock financial transformation with our expertise. We handle the development complexities, empowering you to channel energy into innovation and startup growth.",
          icon: strategic,
          icon2: strategic2,
        },
      ],
    },
    {
      video_link: "",
    },
    {
      title: "Accelerate Your Development Journey",
      subTitle: "",
      subTitle2: "Why Choose Us?",
      cards: [
        {
          title: "Efficient Launch",
          subTitle:
            "Accelerate your product development with InvoZone. Our agile approach speeds up digital solutions, saving time and costs. Focus on refining core functionalities without the complexities of building from scratch.",
          image: "",
        },
        {
          title: "Tailored Solutions",
          subTitle:
            "Forge your unique path with our modular approach. Customize prebuilt components to align with your needs, creating a distinct solution that resonates with your vision.",
          image: "",
        },
        {
          title: "Enhanced User Experiences",
          subTitle:
            "Elevate customer satisfaction through our user-centric approach. Our interfaces, personalized services, and seamless interactions drive customer loyalty and revenue growth.",
          image: "",
        },
        {
          title: "Full-Spectrum Support",
          subTitle:
            "From idea to execution, InvoZone supports you. Access tailored app development, personalized designs, training, and comprehensive support. Our commitment ensures a seamless and successful development journey.",
          image: "",
        },
      ],
    },
    {
      title: "We Build All Kinds of Fintech Apps",
      subTitle2: "What We Offer",
      cards: [
        {
          title: "Blockchain and Cryptocurrency",
          subTitle:
            "Our fintech app development services cater to the intricate needs of blockchain and cryptocurrency solutions. Create secure, decentralized platforms that redefine the financial landscape.",
          icon: blockchain,
        },
        {
          title: "Insurance (InsurTech)",
          subTitle:
            "Elevate insurance services with our app development. Streamline processes, enhance customer experiences, and embrace digital transformation within the insurance sector.",
          icon: insurance,
        },
        {
          title: "Regulatory (RegTech)",
          subTitle:
            "Stay compliant effortlessly with our fintech app development. Empower your business with advanced solutions that ensure adherence to complex regulatory standards.",
          icon: Regulatory,
        },
        {
          title: "Lending (LendTech)",
          subTitle:
            "Revolutionize lending experiences with our app development expertise. Create platforms that simplify lending processes, increasing accessibility and efficiency.",
          icon: Lending,
        },
        {
          title: "Payments (PayTech)",
          subTitle:
            "Transform payment landscapes with our fintech app development. Build secure and flexible payment solutions that cater to diverse customer needs.",
          icon: Payments,
        },
        {
          title: "Mobile Payments",
          subTitle:
            "Enhance mobile payment experiences with our app development. Create user-friendly interfaces that facilitate seamless and secure transactions on-the-go.",
          icon: mobile,
        },
        {
          title: "Trading (TradeTech)",
          subTitle:
            "Empower trading with our fintech app development services. Deliver innovative solutions that optimize trading experiences and decision-making processes.",
          icon: trading,
        },
        {
          title: "Robo-Advising and Stock-Trading Apps",
          subTitle:
            "Enter the world of robo-advising and stock-trading with confidence. Our app development solutions pave the way for personalized and efficient investment experiences.",
          icon: robo,
        },
      ],
      buttons: [
        {
          title: "Start a conversation",
          url: "contact-us",
        },
      ],
    },
    {},
    {
      title: "Some Of Our Featured Works",
      subTitle2: "Portfolio",
      cards: [
        {
          img: Nymcard,
          title: `NymCard`,
          subTitle: `Access 150+ APIs for Prepaid, Multi-currency, Charge, and Credit cards. Launch innovative solutions while we handle the complexity.`,
          link: "https://nymcard.com/",
        },
        {
          img: Acorns,
          link: "https://www.acorns.com/",
          title: `Acorns`,
          subTitle: `Invest spare change, bank and invest together, earn bonus investments, and expand your financial knowledge.`,
        },
        {
          img: Akru,
          link: "https://akrunow.com/",
          title: `AkruNow`,
          subTitle: `Invest with ease, backed by an experienced team with capital market licenses. Transforming complicated investing into simplicity.`,
        },
        {
          img: SpiderAF,
          link: "https://spideraf.com/",
          title: `Spider AF`,
          subTitle: `Optimize campaigns efficiently and build revenue-generating leads with Spider AF, minimizing ad fraud.`,
        },
        {
          img: Forti,
          link: "https://forti.io/",
          title: `Forti.io`,
          subTitle: `Join creators, friends, or colleagues to play and win exclusive prizes. Elevate engagement through interactive game shows.`,
        },
      ],
    },
    {
      title: "Begin Your Journey in 3 Simple Steps",
      cards: [
        {
          title: "Connect",
          subTitle:
            "Start by reaching out to us. Let's discuss your fintech app development needs and explore how we can bring your vision to life.",
          icon: step1,
          icon2: step01,
        },
        {
          title: "Share Your Vision",
          subTitle:
            "Share your project requirements, ideas, and goals with us. Our team will carefully analyze and customize a solution that aligns perfectly with your vision.",
          icon: step2,
          icon2: step02,
        },
        {
          title: "Witness the Transformation",
          subTitle:
            "Sit back and watch as your fintech app takes shape. Our experts will diligently craft your solution, turning your concept into a cutting-edge reality.",
          icon: step3,
          icon2: step03,
        },
      ],
      buttons: [
        {
          title: "Bring your vision into reality",
          url: "contact-us",
        },
      ],
    },
    {
      title: "Frequently Asked Questions",
      cards: [
        {
          title:
            "What types of fintech app development services do you provide?",
          description:
            "Our fintech app development services encompass a diverse array of sectors, including blockchain, InsurTech, LendTech, TradeTech, and more, catering to a wide spectrum of fintech needs.",
        },
        {
          title:
            "How do you ensure that the app meets our specific requirements?",
          description:
            "We commence every project with a meticulous analysis of your unique needs. Through collaborative consultation, we tailor our solutions to align seamlessly with your vision and objectives.",
        },
        {
          title:
            "What distinguishes your fintech app development approach from others?",
          description:
            "With over 15 years of industry expertise, our team's profound knowledge, customer-centric ethos, and unwavering commitment to innovation set us apart, positioning us as pioneers in the field.",
        },
        {
          title:
            "Can you handle the intricate regulatory aspects of the fintech sector?",
          description:
            "Certainly. Our adept team is adept at navigating the intricate regulatory landscape within the fintech sector. Rest assured, your app will comply with all relevant regulations.",
        },
        {
          title: "How long does the typical development process take?",
          description:
            "The duration of development varies, contingent on the complexity of your project. However, our agile methodology ensures streamlined development while upholding the highest standards of quality.",
        },
      ],
    },
  ]

  const heroSection = en_ver[0]
  const discover = en_ver[1]
  const video_link = en_ver[2]
  const accelerate = en_ver[3]
  const build = en_ver[4]
  const kinds = en_ver[5]
  const featured = en_ver[6]
  const testimonials = en_ver[7]
  const faqs = en_ver[8]

  // const { metaTitle, metaDescription } = data?.strapiPage?.seo

  return (
    <MainLayout>
      <Toggle location={location} />
      <Banner strapiData={heroSection} />
      <Discover strapiData={discover} />
      <VideoSection />
      <Accelerate strapiData={accelerate} />
      <Build strapiData={build} />
      {!isMobile && !isTablet ? <Kinds /> : <Kind2 strapiData={build} />}
      {!isMobile ? (
        <Featured strapiData={featured} />
      ) : (
        <Featured2 strapiData={featured} />
      )}
      <Testimonials strapiData={testimonials} />

      <Faqs2 strapiData={faqs} />
    </MainLayout>
  )
}

export default FintechKSA
